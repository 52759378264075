'use client' // Error components must be Client Components

import Link from 'next/link'
import { useEffect } from 'react'

import { Button } from '@wartek-id/button'
import { Icon } from '@wartek-id/icon'

import { EmptyState } from '@/components/EmptyState'

export default function Error({
  error,
}: {
  error: Error & { digest?: string }
}) {
  useEffect(() => {
    // Log the error to an error reporting service
    console.error(error)
  }, [error])
  return (
    <div>
      <EmptyState
        title="Oops, terjadi kesalahan"
        description="Terjadi kesalahan pada sistem saat mencoba mengunjungi halaman yang Anda inginkan."
        customImage="/images/img-forbiden-access.svg"
      >
        <div className="text-center">
          <Button as={Link} href="/">
            <Icon color="inverse" className="mr-2">
              arrow_back
            </Icon>
            Kembali ke Beranda
          </Button>
        </div>
      </EmptyState>
    </div>
  )
}
