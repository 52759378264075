'use client'

import clsx from 'clsx'
import { PropsWithChildren } from 'react'

import { Text } from '@wartek-id/text'

import { ASSET_PREFIX } from '@/configs/constants'

export interface EmptyStateProps {
  title: string
  description: string | JSX.Element
  className?: string
  sectionClassName?: string
  size?: 'lg' | 'md' | 'sm'
  customImage?: string
}

const VARIANT = {
  lg: 'headline-sm',
  md: 'headline-xs',
  sm: 'display-lg',
}

const TEXT_SPACING = {
  lg: 'py-16',
  md: 'py-8',
  sm: 'py-6',
}

const IMAGE_HEIGHT = {
  lg: 'h-52',
  md: 'h-48',
  sm: 'h-36',
}

const DEFAULT_IMAGE = '/images/img-empty-search-result.svg'

export function EmptyState({
  title,
  description,
  className,
  sectionClassName,
  size = 'md',
  customImage = DEFAULT_IMAGE,
  children,
}: PropsWithChildren<EmptyStateProps>) {
  return (
    <div className={clsx('flex flex-col bg-white p-8', className)}>
      <section
        className={clsx(
          'mx-auto flex w-full max-w-7xl flex-grow flex-col justify-center rounded-lg p-4 sm:p-6 lg:p-8',
          sectionClassName
        )}
      >
        <div className="flex flex-shrink-0 justify-center">
          <div className="inline-flex">
            <span className="sr-only">Empty result</span>
            <img
              className={clsx('w-auto', IMAGE_HEIGHT[size])}
              src={`${ASSET_PREFIX}${customImage || DEFAULT_IMAGE}`}
              alt="Lup dengan tanda tanya"
              loading="lazy"
              data-testid="img-empty-state"
            />
          </div>
        </div>
        <div
          className={clsx(
            'flex flex-col items-center justify-center gap-4',
            TEXT_SPACING[size]
          )}
        >
          <div className="text-center">
            <Text
              as="h1"
              // @ts-ignore
              variant={VARIANT[size]}
              data-testid="txt-title-empty-state"
            >
              {title}
            </Text>
            <Text
              className="mt-4"
              variant="body-lg"
              data-testid="txt-description-empty-state"
            >
              {description}
            </Text>
          </div>
          {children ? <>{children}</> : null}
        </div>
      </section>
    </div>
  )
}
